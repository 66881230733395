import React from 'react';

import { PulseLoader, RotateLoader, PropagateLoader, ScaleLoader, ClipLoader } from 'react-spinners';

interface P{
    show: boolean,
    mode: "light" | "dark" | "white", 
    type: "pulse" | "rotate" | "propagate" | "scale" | "clip",
}
interface S{}

export default class LoadingIndicator extends React.Component<P,S> {

    constructor(props: P){
        super(props);

        this.state = {

        }
    }

    COLOR = this.props.mode === 'dark'? "rgb( 12, 255, 0 )" : "#6ED482"
    BG_COLOR = this.props.mode === 'dark'? "rgba( 0, 0, 0, 0.35 )" : this.props.mode === "white"? 'white' : "rgba( 0, 0, 0, 0.08 )" 

    render() {
        if ( this.props.show ){
            return (
                <div 
                    style={{ 
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%', 
                        width: '100%', 
                        top: 0,
                        left: 0,
                        backgroundColor: this.BG_COLOR,
                        borderRadius: "inherit",
                        zIndex: 999999
                    }}
                >
                    { this._renderLoader() }
                </div>
            )
        }

        return null
    }

    _renderLoader = () => {
        const { type } = this.props
        switch( type ){
            case "pulse":
                return <PulseLoader size={ 15 } margin='10px' color={ this.COLOR }/>

            case "rotate":
                return <RotateLoader size={ 15 } margin='1px' color={ this.COLOR }/>

            case "propagate":
                return <PropagateLoader size={ 15 } color={ this.COLOR }/>

            case "scale":
                return <ScaleLoader height={ 25 } width={ 5 } radius={ 5 } margin='5px' color={ this.COLOR }/>

            case "clip":
                return <ClipLoader size={ 25 } color={ this.COLOR }/>
                
            default:
                return <PulseLoader size={ 15 } margin='10px' color={ this.COLOR }/>
        }
    }
}