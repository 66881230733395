export function ellipsizeThis(x: string, leftCharLength: number, rightCharLength: number) {
    return x.substring(0, leftCharLength) + "..." + x.substr(-rightCharLength);
}

export function shortNumber(x: string | number, decimalPlaces = 0): string{
    let power = 1;

    if(typeof(x) == "string"){
        x = parseFloat(x);
    }

    let isIncrease = Math.abs(x) >= 1;
    let isNegative = x < 0;

    x = Math.abs(x); //ignore negative

    if(x >= 1000 || x <= 0.001){
        while(true){
            if(isIncrease){
                x = x / 1000;
                power += 3;
    
                if(x < 1000){
                    break;
                }
            }
    
            else {
                x = x * 1000;
                power -= 3;
    
                if(x >= 1){
                    break;
                }
            }
    
            //prevent endless loop
            if(power < -9 || power > 12){
                break;
            }
        }
    }
    
    if(isNegative){
        x = -x;
    }

    let suffix = "";

    if(power >= 12){
        suffix = "T";
    }

    else if(power >= 9){
        suffix = "B";
    }

    else if(power >= 6){
        suffix = "M";
    }

    else if(power >= 3){
        suffix = "k";
    }
    else if(power >= 0){
        suffix = "";
    }
    else if(power >= -3){
        suffix = "m";
    }
    else if(power >= -6){
        suffix = "u";
    }
    else if(power >= -9){
        suffix = "p";
    }

    else {
        x = 0;
    }

    return x.toFixed(decimalPlaces) + suffix;

}