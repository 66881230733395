import { Component, createRef } from 'react';
import axios from 'axios';
import angelprotocol from '../../Images/angelprotocol.png';
import './style.scss';
import LoadingIndicator from '../../Components/LoadingIndicator';
import { ellipsizeThis, shortNumber } from '../../Utils/fn';
import { CSVLink } from 'react-csv';
//import moment from 'moment';
import { Collapse } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

interface S {
    anchorDelegatorTotalData: ShortDelegatorTotalData[];
    anchorDelegatorSearchData: ShortDelegatorTotalData[];
    angelDelegatorTotalData: DelegatorTotalData[];
    lunaTotalData: LunaticScoreData[];

    csvData: string[][];
    hadSearched: boolean;

    isAngelLoading: boolean;
    angelStakerPage: number;
    angelStakerSearch: string;
    angelSearchData: DelegatorTotalData[];

    isAnchorLoading: boolean;
    anchorStakerPage: number;
    anchorStakerSearch: string;
    anchorSearchData: DelegatorTotalData[];

    isLunaLoading: boolean;
    lunaScorePage: number;
    lunaScoreSearch: string;
    lunaSearchData: LunaticScoreData[];

    totalAngelScore: number;
    totalAncScore: number;
    totalEligibleAddresses: string;
    totalAngelAddresses: string;
    totalAncAddresses: string;
    totalLunaAddresses: string;

    csvPrepared: boolean;
    isPreparingCSV: boolean;

    //tooltip
    showCalcForAnc: boolean;
    showCalcForAngel: boolean;
    showCalcForLunatic: boolean;
}

interface P {

}

interface DelegatorTotalData {
    DELEGATOR: string;
    POINTS_ACCRUED: number;
    RANK: number;
}

interface ShortDelegatorTotalData {
    delegator: string;
    points_accrued: number;
}

interface LunaticScoreData {
    address: string;
    score: number;
    tokens: number;
    missing_tokens: number;
}

interface DelegatorScores { 
    [delegator: string] : {
        angel: number;
        anc: number;
        luna: {
            score: number;
            tokens: number;
            missing_tokens: number;
        };
    }
}

const ANGEL_HALO = 27500000;
const ANC_HALO = 13750000;
//const ANC_START_MOMENT = moment('2021-10-05 00:00:00');

interface DownloadButtonProps {
    csvData: string[][];
}

interface DownloadButtonState {
    hasUpdated: boolean;
}

class CSVDownloadButton extends Component<DownloadButtonProps, DownloadButtonState> {

    constructor(props: any) {
        super(props);

        this.state = {
            hasUpdated: false,
        }
    }

    shouldComponentUpdate = (nextProps: DownloadButtonProps) => {
        return nextProps.csvData.length !== 0 && !this.state.hasUpdated;
    }

    componentDidUpdate = () => {
        if(this.props.csvData.length !== 0 && !this.state.hasUpdated) {
            this.setState({
                hasUpdated: true
            });
        }
    }

    render() {
        return (
            <CSVLink className="btn btn-angel btn-info mt-2 max-width-button" data={this.props.csvData} filename={'scores.csv'} target="_blank">Download scores</CSVLink>
        )
    }
}

export class HomePage extends Component<P,S> {

    target = createRef<HTMLElement>();
    csvRef = createRef<HTMLElement>();

    constructor(props: any) {
        super(props);

        this.state = {
            anchorDelegatorTotalData: [],
            anchorDelegatorSearchData: [],
            angelDelegatorTotalData: [],
            lunaTotalData: [],

            csvData: [],
            hadSearched: false,

            isAngelLoading: true,
            angelStakerPage: 0,
            angelStakerSearch: '',
            angelSearchData: [],

            isAnchorLoading: true,
            anchorStakerPage: 0,
            anchorStakerSearch: '',
            anchorSearchData: [],

            isLunaLoading: true,
            lunaScorePage: 0,
            lunaScoreSearch: '',
            lunaSearchData: [],

            totalAngelScore: 0,
            totalAncScore: 0,
            totalEligibleAddresses: '0',
            totalAncAddresses: '0',
            totalAngelAddresses: '0',
            totalLunaAddresses: '0',

            csvPrepared: false,
            isPreparingCSV: false,

            showCalcForAnc: false,
            showCalcForAngel: false,
            showCalcForLunatic: false
        };
    }

    componentDidMount = async() => {
        document.title = 'Angel Airdrop Points';
        document.getElementById("favicon")!.setAttribute('href', '/angel-favicon.png');
        document.getElementById("apple-favicon")!.setAttribute('href', '/angel-favicon.png');
        document.getElementById("App")!.setAttribute('class', 'App theme-angel');

        try {
            //get delegator data
            let [
                angelDelegatorTotalData,
                anchorDelegatorScoreData,
                anchorTotalScoreData,
                lunaTotalData,
                lunaticAddressCountData
            ] = await axios.all([
                axios.get('https://api.flipsidecrypto.com/api/v2/queries/f3c8b03d-b809-432d-b051-818662ea46e3/data/latest'),
                axios.get(`https://flipside.leslug.com/gov/anc/angel_current_score`),
                axios.get(`https://flipside.leslug.com/gov/anc/angel_total_score`),
                axios.get(`https://flipside.leslug.com/angel/lunatic_score/100/1`),
                axios.get(`https://flipside.leslug.com/angel/lunatic_address_count`),
            ]);

            let totalAngelScore = 0;
            angelDelegatorTotalData.data.forEach((x: DelegatorTotalData) => {
                totalAngelScore += x.POINTS_ACCRUED;
            });

            let anchorDelegatorTotalData: ShortDelegatorTotalData[] = anchorDelegatorScoreData.data;

            let totalEligibleAddresses = lunaticAddressCountData.data.count.toLocaleString('en');
            let totalAngelAddresses = angelDelegatorTotalData.data.length.toLocaleString('en');
            let totalAncAddresses = anchorDelegatorTotalData.length.toLocaleString('en');
            let totalLunaAddresses = totalEligibleAddresses;

            this.setState({
                angelDelegatorTotalData: angelDelegatorTotalData.data,
                isAngelLoading: false,
                isAnchorLoading: false,
                isLunaLoading: false,
                anchorDelegatorTotalData: anchorDelegatorTotalData,

                totalAncScore: anchorTotalScoreData.data[0].points_accrued,
                totalAngelScore,
                totalEligibleAddresses,
                totalAngelAddresses,
                totalAncAddresses,
                totalLunaAddresses,

                lunaTotalData: lunaTotalData.data
            }, () => {
                let { hadSearched }  = this.state;
                if(hadSearched) {
                    this._onAngelStakerSearchClick();
                }
                //this._updateCsvData();
            });
            //get anchor data
        }

        catch(e) {
            console.log(e)
        }
    }

    _onAngelStakerLeftClick = () => {
        let { angelStakerPage } = this.state;
        if(angelStakerPage > 0) {
            angelStakerPage--;
            this.setState({
                angelStakerPage
            });
        }
    }

    _onAngelStakerRightClick = () => {
        let { angelStakerPage, angelDelegatorTotalData } = this.state;
        if((angelStakerPage + 1) * 10 < angelDelegatorTotalData.length) {
            angelStakerPage++;
            this.setState({
                angelStakerPage
            });
        }
    }

    _onAngelPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { angelDelegatorTotalData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > angelDelegatorTotalData.length) {
            page = Math.floor(angelDelegatorTotalData.length / 10);
        }

        this.setState({
            angelStakerPage: page
        });
    }

    _onAngelStakerChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            angelStakerSearch: event.currentTarget.value
        });
    }

    _onAngelStakerClear = () => {
        this.setState({
            angelStakerSearch: ''
        }, this._onAngelStakerSearchClick);
    }

    _onAngelStakerSearchClick = async () => {
        let { angelDelegatorTotalData, angelStakerSearch, isAngelLoading, anchorDelegatorTotalData, lunaTotalData } = this.state;

        if((angelStakerSearch.length !== 44 || angelStakerSearch.substring(0, 6) !== 'terra1') && angelStakerSearch !== "") {
            alert('Invalid Terra Address!');
            return;
        }

        this.setState({
            hadSearched: true
        });

        if(isAngelLoading) {
            return;
        }

        let data = angelDelegatorTotalData.filter(x => x.DELEGATOR.includes(angelStakerSearch));
        let anchorData = anchorDelegatorTotalData.filter(x => x.delegator.includes(angelStakerSearch));
        let lunaData = angelStakerSearch? (await axios.get(`https://flipside.leslug.com/angel/lunatic_score/address/${angelStakerSearch}`)).data : lunaTotalData;

        this.setState({
            angelSearchData: data,
            angelStakerPage: 0, //reset
            anchorStakerPage: 0, //reset
            lunaScorePage: 0, //reset
            anchorDelegatorSearchData: anchorData,
            lunaSearchData: lunaData,
        });
    }

    _onAnchorStakerLeftClick = () => {
        let { anchorStakerPage } = this.state;
        if(anchorStakerPage > 0) {
            anchorStakerPage--;
            this.setState({
                anchorStakerPage
            });
        }
    }

    _onAnchorStakerRightClick = () => {
        let { anchorStakerPage, anchorDelegatorTotalData } = this.state;
        if((anchorStakerPage + 1) * 10 < anchorDelegatorTotalData.length) {
            anchorStakerPage++;
            this.setState({
                anchorStakerPage
            });
        }
    }

    _onAnchorPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { anchorDelegatorTotalData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > anchorDelegatorTotalData.length) {
            page = Math.floor(anchorDelegatorTotalData.length / 10);
        }

        this.setState({
            anchorStakerPage: page
        });
    }

    _onLunaStakerLeftClick = () => {
        let { lunaScorePage } = this.state;
        if(lunaScorePage > 0) {
            lunaScorePage--;
            this.setState({
                lunaScorePage
            });
        }
    }

    _onLunaStakerRightClick = () => {
        let { lunaScorePage, lunaTotalData } = this.state;
        if(lunaScorePage < lunaTotalData.length) {
            lunaScorePage++;
            this.setState({
                lunaScorePage
            });
        }
    }

    _onLunaPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { lunaTotalData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > lunaTotalData.length) {
            page = Math.floor(lunaTotalData.length / 10);
        }

        this.setState({
            lunaScorePage: page
        });
    }

    _prepareCSV = async() => {
        this.setState({
            isPreparingCSV: true,
        })
        let lunaTotalData = await axios.get(`https://flipside.leslug.com/angel/lunatic_score`);
        this.setState({
            lunaTotalData: lunaTotalData.data,
            isPreparingCSV: false
        }, () => {
            this._updateCsvData();
        });
    }

    render() {
        let { 
            angelStakerPage,
            angelSearchData, 
            isAngelLoading, 
            isAnchorLoading, 
            totalEligibleAddresses, 
            totalAncAddresses, 
            totalAngelAddresses, 
            totalLunaAddresses, 
            csvData, 
            showCalcForAnc, 
            showCalcForAngel, 
            showCalcForLunatic,
            totalAncScore,
            totalAngelScore,
            csvPrepared,
            isPreparingCSV,
        } = this.state;
        
        return (
            <div>
                <div className="header">
                    <a id="header-href" href="https://www.angelprotocol.io/"><img src={angelprotocol} alt="null" id="logo"></img></a>
                </div>
                <div className="content">
                    <h1>Overview</h1>
                    <p>Angel Protocol will airdrop a total of {(ANC_HALO * 2 + ANGEL_HALO).toLocaleString('en')} $HALO tokens to Angel Validator delegators, Anchor Governance Stakers, and LUNAtics who are active in the Terra ecosystem based on <a href="https://science.flipsidecrypto.com/lunatics" target="_blank" rel="noopener noreferrer" className="text-info">Flipside's LUNAtic Score</a>*.</p>

                    <p>
                        The $HALO tokens allocated to each category are as follows:
                    </p>

                    <ul className="text-start">
                        <li>Angel Validator delegators: {(ANGEL_HALO).toLocaleString('en')} $HALO</li>
                        <li>Anchor Governance stakers: {(ANC_HALO).toLocaleString('en')} $HALO</li>
                        <li>LUNAtics: {(ANC_HALO).toLocaleString('en')} $HALO</li>
                    </ul>
                    
                    <p>
                        Planned milestone releases for airdrops: 
                    </p>

                    <ul className="text-start">
                        <li>Charity Marketplace launch in February 2022 (20%) </li>
                        <li>Bonding curve functionality launch in Q2 (40%)  </li>
                        <li>Charities onboard passes 300 charity mark (10%)  </li>
                        <li>TVL** passes $10M mark (15%) </li>
                        <li>TVL passes $25M mark (15%) </li>
                    </ul>

                    <div className="text-start mb-5">
                        <span style={{fontStyle: 'italic'}}>* Airdrop amount changes over time, snapshots will be taken when each of the milestone is achieved. Importantly, this means if you un-stake you will continue to receive airdrops throughout the year, BUT keep staking and you will earn more based on cumulative length and amount of staking. </span>
                        <br />
                        <br />
                        <span style={{fontStyle: 'italic'}}>** Total Value Locked (TVL)  is assessed as the total amount in endowment accounts (whether in locked principle or withdrawable current/liquid acct), inclusive of the communal AP Charity Endowment and "Locked for Good" token endowments</span>
                    </div>

                    <div className="d-flex flex-row flex-wrap justify-content-between mt-3">
                        <div className="transparent-card big">
                            <span>Total Eligible Addresses</span>
                            <strong>{totalEligibleAddresses}</strong>
                        </div>
                        <div className="transparent-card small">
                            <span>Total Angel Delegators</span>
                            <strong>{ totalAngelAddresses }</strong>
                        </div>
                        <div className="transparent-card small">
                            <span>Total LUNAtic Addresses</span>
                            <strong>{ totalLunaAddresses }</strong>
                        </div>
                        <div className="transparent-card small">
                            <span>Total ANC Stakers</span>
                            <strong>{ totalAncAddresses }</strong>
                        </div>
                    </div>

                    {
                        !csvPrepared &&
                        !isPreparingCSV &&
                        <button className='btn btn-angel btn-info mt-2 max-width-button' onClick={this._prepareCSV}>Download CSV (Will take a minute to prepare)</button>
                    }
                    {
                        isPreparingCSV &&
                        <button className='btn btn-angel btn-info mt-2 max-width-button' disabled onClick={this._prepareCSV}><i className="fa fa-spin fa-spinner fa-2x"></i></button>
                    }
                    {
                        csvPrepared &&
                        <CSVDownloadButton csvData={csvData}/>
                    }

                    <h3>Angel Delegators</h3>
                    <p>
                        <strong>Formula: </strong>Number of LUNA staked to Angel x Duration in Seconds
                        <button
                            onClick={() => { this.setState({ showCalcForAngel: !showCalcForAngel })}}
                            className={'invisible-button btn btn-primary'}
                        >
                            <i className="fas fa-info-circle"></i>
                        </button>
                        <br />
                        <strong>Start Date:</strong> 2021-06-16 00:00:00 UTC
                    </p>
                    <Collapse in={showCalcForAngel}>
                        <div className="card p-3" style={{ borderRadius: 12 }}>
                            <p>Angel Protocol is building a world where all charities are financially free.</p>
                            <p>Those who had delegated to the Angel Validator had given 100% of their staking rewards to Angel.</p>
                            <p>Thus, 50% of the total airdropped tokens are allocated to those who had delegated to Angel for their kind-heartedness.</p>
                            <p>Rewards are calculated by amount of LUNA staked multiplied by time staked, since larger LUNA delegations amounted to more rewards given to Angel.</p>
                            <p>For more info, please visit <a href="https://angelprotocol.medium.com/the-angel-protocol-validator-46b8722ed213" target="_blank" rel="noopener noreferrer" className="text-info">this Medium article</a>.</p>
                            <br />
                            <p><strong>Total Tokens</strong><br />{(ANGEL_HALO).toLocaleString('en')} $HALO</p>
                            <br />
                            <p><strong>Date</strong><br />From 2021-06-16 00:00:00 UTC - Now</p>
                            <br />
                            <p><strong>Airdrop Calculation</strong></p>
                            <p>Number of LUNA delegated to Angel x duration in seconds = score</p>
                            <p>For example, if a person delegated 1 LUNA for 2 weeks before undelegating or redelegating to another validator, the score is 1 x 60 x 60 x 24 x 14 = 1,209,600</p>
                            <p>{`$HALO Airdropped = (Score / Total Score) * ${ANGEL_HALO.toLocaleString('en')} * % for milestone`}</p>
                            <br />
                            <p><strong>Total Score</strong></p>
                            <p>{`${totalAngelScore.toLocaleString('en', {maximumFractionDigits: 6})}`}</p>
                        </div>
                    </Collapse>
                    <div className="mt-5 d-flex justify-content-between w-100">
                        <input type="text" className={`card address-input`} placeholder="terra1..." value={this.state.angelStakerSearch} onChange={this._onAngelStakerChange}></input>
                        <button className="btn btn-sm btn-info btn-search" onClick={this._onAngelStakerSearchClick}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="card mt-2 table-container">
                        <LoadingIndicator
                            show={this.state.isAngelLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Address</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderAngelStakerScore()}
                            </tbody>
                        </table>
                        {
                            !isAngelLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className={`btn btn-sm ${angelStakerPage === 0? 'disabled': ''}`} onClick={this._onAngelStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={angelStakerPage + 1} onChange={this._onAngelPageChange}/>
                                <button className={`btn btn-sm ${angelStakerPage >= this.state.angelDelegatorTotalData.length || ((angelStakerPage + 1) * 10 >= angelSearchData.length && angelSearchData.length !== 0)? 'disabled': ''}`} onClick={this._onAngelStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                    <h3>ANC Gov Stakers</h3>
                    <p>
                        <strong>Formula: </strong>
                        Number of ANC staked (in shares) x Duration in Seconds 
                        <button
                            onClick={() => { this.setState({ showCalcForAnc: !showCalcForAnc })}}
                            className={'invisible-button btn btn-primary'}
                        >
                            <i className="fas fa-info-circle"></i>
                        </button>
                        <br></br>
                        <strong>Start Date:</strong> 2021-10-05 00:00:00 UTC
                    </p>

                    <Collapse in={showCalcForAnc}>
                        <div className="card p-3" style={{ borderRadius: 12 }}>
                            <p>Anchor Protocol is a protocol that allows users to deposit, borrow, and lend UST.</p>
                            <p>As of 22 December 2021, Anchor's TVL surpassed 10B for the first time in history.</p>
                            <p>Needless to say, Anchor's importance in Terra's ecosystem is undeniable.</p>
                            <p>Thus, Angel Protocol would like to reward those who had staked ANC tokens in governance (ANC-UST pool is excluded).</p>
                            <br />
                            <p><strong>Total Tokens</strong><br />{(ANC_HALO).toLocaleString('en')} $HALO</p>
                            <br />
                            <p><strong>Date</strong><br />From 2021-10-05 00:00:00 UTC - Now</p>
                            <br />
                            <p><strong>Airdrop Calculation</strong></p>
                            <p>Number of ANC shares x duration in seconds = score</p>
                            <p>For example, if a person delegated 1 share's worth of ANC tokens for 2 weeks before unstaking, the score is 1 x 60 x 60 x 24 x 14 = 1,209,600</p>
                            <p>A person's share is determined by the share ratio at the time of staking.</p>
                            <p>For example, when the share ratio = 1.3, 1.3 ANC staked = 1 share.</p>
                            <p>{`$HALO Airdropped = (Score / Total Score) * ${ANC_HALO.toLocaleString('en')} * % for milestone`}</p>
                            <br />
                            <p><strong>Total Score</strong></p>
                            <p>{`${totalAncScore.toLocaleString('en', {maximumFractionDigits: 6})}`}</p>
                        </div>
                    </Collapse>
                    <div className="card mt-5 table-container">
                        <LoadingIndicator
                            show={this.state.isAnchorLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Address</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderAnchorStakerScore()}
                            </tbody>
                        </table>
                        {
                            !isAnchorLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className="btn btn-sm" onClick={this._onAnchorStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={this.state.anchorStakerPage + 1} onChange={this._onAnchorPageChange}/>
                                <button className="btn btn-sm" onClick={this._onAnchorStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                    <h3>LUNAtic Score (Displaying only 100 for faster page loading)</h3>
                    <p>
                        <strong>Source: </strong>
                        <a href="https://science.flipsidecrypto.com/lunatics" target="_blank" rel="noopener noreferrer">The LUNAtic Score <i className="fas fa-link"></i></a> 
                        <button
                            onClick={() => { this.setState({ showCalcForLunatic: !showCalcForLunatic })}}
                            className={'invisible-button btn btn-primary'}
                        >
                            <i className="fas fa-info-circle"></i>
                        </button>
                        <br />
                        <strong>Snapshot Date: </strong> 14 February 2022
                    </p>
                    <Collapse in={showCalcForLunatic}>
                        <div className="card p-3" style={{ borderRadius: 12 }}>
                            <div className="text-start">
                                <p>The LUNAtic Score is a scoring system for Terra addresses developed by <a href="https://flipsidecrypto.com" className="text-info" target="_blank" rel="noopener noreferrer">Flipside Crypto</a> that recognizes the contributions of an address to the Terra Ecosystem.</p>
                                <p>The $HALO airdrop intends to reward these addresses as, without their support and activities, Terra wouldn't be where it is today.</p>
                                <p>The LUNAtic Score is based on a 30-point scoring system, points can be obtained by completing several tasks. For more info, please visit <a href="https://science.flipsidecrypto.com/lunatics" className="text-info" target="_blank" rel="noopener noreferrer">their website</a>.</p>
                                <p>The snapshot for the LUNAtic Score is taken on 16 December 2021.</p>
                                <p>To reward those who are truly active in Terra's ecosystem, we have decided that a tiered exponential graph best suits our intend, where the most active addresses are handsomely rewarded compared to the less active ones.</p>
                                <br />
                                <p><strong>Total Tokens</strong><br />{(ANC_HALO).toLocaleString('en')} $HALO</p>
                                <br />
                                <p><strong>Snapshot Date</strong><br />14 February 2022</p>
                                <br />
                                <p><strong>Airdrop Calculation</strong></p>
                                <p>{'When Score >= 25, Adjusted LUNAtic Score = 1.30^Score'}</p>
                                <p>{'When Score >= 20, Adjusted LUNAtic Score = 1.29^Score'}</p>
                                <p>{'When Score >= 15, Adjusted LUNAtic Score = 1.28^Score'}</p>
                                <p>{'When Score >= 10, Adjusted LUNAtic Score = 1.27^Score'}</p>
                                <p>{'When Score >= 5, Adjusted LUNAtic Score = 1.26^Score'}</p>
                                <p>{`$HALO Airdropped = (Score / Total Score) * ${ANC_HALO.toLocaleString('en')} * % for milestone`}</p>
                                <br />
                                <a href="/Angel_Airdrop_Calculations.xlsx" className="btn btn-success btn-angel">Download Detailed Calculation</a>
                            </div>
                        </div>
                    </Collapse>
                    <p style={{marginTop: showCalcForLunatic? 10 : 0}}>
                        <strong>Distribution Graph: </strong>
                    </p>
                    <img src="/angel_lunatic_score.png" alt="angel_lunatic_score" style={{ height: 'auto', width: '100%', borderRadius: 12 }}/>
                    <div className="card mt-5 table-container">
                        <LoadingIndicator
                            show={this.state.isLunaLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Address</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderLunaticScore()}
                            </tbody>
                        </table>
                        {
                            !this.state.isLunaLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className="btn btn-sm" onClick={this._onLunaStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={this.state.lunaScorePage + 1} onChange={this._onLunaPageChange}/>
                                <button className="btn btn-sm" onClick={this._onLunaStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                    <a className="btn btn-angel btn-success mt-5 mb-5 max-width-button" href="/angel/single">Click here for detailed data</a>
                </div>
            </div>
        )
    }

    _renderAngelStakerScore = () => {
        let { angelSearchData, angelDelegatorTotalData, angelStakerPage, hadSearched, totalAngelScore } = this.state;
        if(angelSearchData.length !== 0 || hadSearched) {
            return (
                angelSearchData.filter((x, i) => i >= angelStakerPage * 10 && i < (angelStakerPage + 1) * 10).map((x,i) => (
                    <tr key={`angel-stake-${i}`}>
                        <td>{x.RANK}</td>
                        <td><a href={`/angel/single/${x.DELEGATOR}`} style={{color: 'blue'}}>{ellipsizeThis(x.DELEGATOR, 6, 6)}</a></td>
                        <td>
                            {shortNumber(x.POINTS_ACCRUED, 3)} <br />{shortNumber(x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore, 2)} HALO
                            <i className="fas fa-info-circle" style={{marginLeft: 5}}  data-tip data-for={`angel-info-${i}`}></i>
                            <ReactTooltip id={`angel-info-${i}`} place='right' effect='solid'>
                                <div className="d-flex flex-column">
                                    <span>40% - {shortNumber((x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore) * 0.4, 2)} HALO</span>
                                    <span>20% - {shortNumber((x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore) * 0.2, 2)} HALO</span>
                                    <span>15% - {shortNumber((x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore) * 0.15, 2)} HALO</span>
                                    <span>10% - {shortNumber((x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore) * 0.1, 2)} HALO</span>
                                </div>
                            </ReactTooltip>
                        </td>
                    </tr>
                ))
            );
        }

        return (
            angelDelegatorTotalData.filter((x, i) => i >= angelStakerPage * 10 && i < (angelStakerPage + 1) * 10).map((x,i) => (
                <tr key={`angel-stake-${i}`}>
                    <td>{x.RANK}</td>
                    <td><a href={`/angel/single/${x.DELEGATOR}`} style={{color: 'blue'}}>{ellipsizeThis(x.DELEGATOR, 6, 6)}</a></td>
                    <td>
                        {shortNumber(x.POINTS_ACCRUED, 3)} <br />{shortNumber(x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore, 2)} HALO
                        <i className="fas fa-info-circle" style={{marginLeft: 5}}  data-tip data-for={`angel-info-${i}`}></i>
                        <ReactTooltip id={`angel-info-${i}`} place='right' effect='solid'>
                            <div className="d-flex flex-column">
                                <span>40% - {shortNumber((x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore) * 0.4, 2)} HALO</span>
                                <span>20% - {shortNumber((x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore) * 0.2, 2)} HALO</span>
                                <span>15% - {shortNumber((x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore) * 0.15, 2)} HALO</span>
                                <span>10% - {shortNumber((x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore) * 0.1, 2)} HALO</span>
                            </div>
                        </ReactTooltip>
                    </td>
                </tr>
            ))
        );
    }

    _renderAnchorStakerScore = () => {
        let { anchorDelegatorSearchData, anchorDelegatorTotalData, hadSearched, anchorStakerPage, totalAncScore } = this.state;
        if(anchorDelegatorSearchData.length !== 0 || hadSearched) {
            return (
                anchorDelegatorSearchData.filter((x, i) => i >= anchorStakerPage * 10 && i < (anchorStakerPage + 1) * 10).map((x,i) => (
                    <tr key={`anchor-stake-${i}`}>
                        <td>{anchorDelegatorTotalData.indexOf(x) + 1}</td>
                        <td><a href={`/angel/single/${x.delegator}`} style={{color: 'blue'}}>{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                        <td>
                            {shortNumber(x.points_accrued, 3)} <br />{shortNumber(x.points_accrued * ANC_HALO / totalAncScore, 2)} HALO
                            <i className="fas fa-info-circle" style={{marginLeft: 5}}  data-tip data-for={`anc-info-${i}`}></i>
                            <ReactTooltip id={`anc-info-${i}`} place='right' effect='solid'>
                                <div className="d-flex flex-column">
                                    <span>40% - {shortNumber((x.points_accrued * ANC_HALO / totalAncScore) * 0.4, 2)} HALO</span>
                                    <span>20% - {shortNumber((x.points_accrued * ANC_HALO / totalAncScore) * 0.2, 2)} HALO</span>
                                    <span>15% - {shortNumber((x.points_accrued * ANC_HALO / totalAncScore) * 0.15, 2)} HALO</span>
                                    <span>10% - {shortNumber((x.points_accrued * ANC_HALO / totalAncScore) * 0.1, 2)} HALO</span>
                                </div>
                            </ReactTooltip>
                        </td>
                    </tr>
                ))
            );
        }

        return (
            anchorDelegatorTotalData.filter((x, i) => i >= anchorStakerPage * 10 && i < (anchorStakerPage + 1) * 10).map((x,i) => (
                <tr key={`anchor-stake-${i}`}>
                    <td>{anchorDelegatorTotalData.indexOf(x) + 1}</td>
                    <td><a href={`/angel/single/${x.delegator}`} style={{color: 'blue'}}>{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                        <td>
                            {shortNumber(x.points_accrued, 3)} <br />{shortNumber(x.points_accrued * ANC_HALO / totalAncScore, 2)} HALO
                            <i className="fas fa-info-circle" style={{marginLeft: 5}}  data-tip data-for={`anc-info-${i}`}></i>
                            <ReactTooltip id={`anc-info-${i}`} place='right' effect='solid'>
                                <div className="d-flex flex-column">
                                    <span>40% - {shortNumber((x.points_accrued * ANC_HALO / totalAncScore) * 0.4, 2)} HALO</span>
                                    <span>20% - {shortNumber((x.points_accrued * ANC_HALO / totalAncScore) * 0.2, 2)} HALO</span>
                                    <span>15% - {shortNumber((x.points_accrued * ANC_HALO / totalAncScore) * 0.15, 2)} HALO</span>
                                    <span>10% - {shortNumber((x.points_accrued * ANC_HALO / totalAncScore) * 0.1, 2)} HALO</span>
                                </div>
                            </ReactTooltip>
                        </td>
                </tr>
            ))
        );
    }

    _renderLunaticScore = () => {
        let { lunaTotalData, lunaSearchData, hadSearched, lunaScorePage } = this.state;
        if(lunaSearchData.length !== 0 || hadSearched) {
            return (
                lunaSearchData.filter((x, i) => i >= lunaScorePage * 10 && i < (lunaScorePage + 1) * 10).map((x,i) => (
                    <tr key={`lunatic-${i}`}>
                        <td>{lunaTotalData.indexOf(x) + 1}</td>
                        <td><a href={`/angel/single/${x.address}`} style={{color: 'blue'}}>{ellipsizeThis(x.address, 6, 6)}</a></td>
                        <td>
                            {shortNumber(x.score, 3)} <br />{shortNumber(x.tokens, 2)} HALO
                            <i className="fas fa-info-circle" style={{marginLeft: 5}}  data-tip data-for={`lunatic-info-${i}`}></i>
                            <ReactTooltip id={`lunatic-info-${i}`} place='right' effect='solid'>
                                <div className="d-flex flex-column">
                                    <span>40% - {shortNumber(x.tokens * 0.4, 2)} HALO</span>
                                    <span>20% - {shortNumber(x.tokens * 0.2, 2)} HALO</span>
                                    <span>15% - {shortNumber(x.tokens * 0.15, 2)} HALO</span>
                                    <span>10% - {shortNumber(x.tokens * 0.1, 2)} HALO</span>
                                </div>
                            </ReactTooltip>
                        </td>
                    </tr>
                ))
            );
        }

        return (
            lunaTotalData.filter((x, i) => i >= lunaScorePage * 10 && i < (lunaScorePage + 1) * 10).map((x,i) => (
                <tr key={`lunatic-${i}`}>
                    <td>{lunaTotalData.indexOf(x) + 1}</td>
                    <td><a href={`/angel/single/${x.address}`} style={{color: 'blue'}}>{ellipsizeThis(x.address, 6, 6)}</a></td>
                    <td>
                        {shortNumber(x.score, 3)} <br />{shortNumber(x.tokens, 2)} HALO
                        <i className="fas fa-info-circle" style={{marginLeft: 5}}  data-tip data-for={`lunatic-info-${i}`}></i>
                        <ReactTooltip id={`lunatic-info-${i}`} place='right' effect='solid'>
                            <div className="d-flex flex-column">
                                <span>40% - {shortNumber(x.tokens * 0.4, 2)} HALO</span>
                                <span>20% - {shortNumber(x.tokens * 0.2, 2)} HALO</span>
                                <span>15% - {shortNumber(x.tokens * 0.15, 2)} HALO</span>
                                <span>10% - {shortNumber(x.tokens * 0.1, 2)} HALO</span>
                            </div>
                        </ReactTooltip>
                    </td>
                </tr>
            ))
        );
    }

    _updateCsvData = () => {
        let { isAngelLoading, isAnchorLoading, anchorDelegatorTotalData, angelDelegatorTotalData, csvData, totalAngelScore, totalAncScore, lunaTotalData } = this.state;

        if(isAngelLoading || isAnchorLoading || csvData.length !== 0) {
            return;
        }

        let delegators: DelegatorScores = {};

        angelDelegatorTotalData.forEach(x => {
            delegators[x.DELEGATOR] = {
                angel: x.POINTS_ACCRUED,
                anc: 0,
                luna: {
                    score: 0,
                    tokens: 0,
                    missing_tokens: 0,
                },
            }; // any number
        });

        anchorDelegatorTotalData.forEach(x => {
            if(!delegators[x.delegator]) {
                delegators[x.delegator] = {
                    angel: 0,
                    anc: 0,
                    luna: {
                        score: 0,
                        tokens: 0,
                        missing_tokens: 0,
                    },
                }
            }
            delegators[x.delegator].anc = x.points_accrued; // any number
        });

        lunaTotalData.forEach(x => {
            if(!delegators[x.address]) {
                delegators[x.address] = {
                    angel: 0,
                    anc: 0,
                    luna: {
                        score: 0,
                        tokens: 0,
                        missing_tokens: 0,
                    },
                }
            }
            delegators[x.address].luna.score = x.score; // any number
            delegators[x.address].luna.tokens = x.tokens; // any number
            delegators[x.address].luna.missing_tokens = x.missing_tokens * 0.2; // 20% of original tokens
        })

        csvData.push([
            'Address',
            'Angel Score',
            'Anchor Score',
            'LUNAtic Score',
            'Angel $HALO',
            'Anchor $HALO',
            'LUNAtic $HALO',
            'Total $HALO',
            'pct 0.1',
            'pct 0.15',
            'pct 0.2',
            'pct 0.4',
            'missing_tokens',
        ]);

        for(const [delegator, values] of Object.entries(delegators)) {
            if(values.anc === 0 && values.angel === 0 && values.luna.score === 0) {
                //dont get 0 values
                continue;
            }

            let ancHalo = values.anc * ANC_HALO / totalAncScore;
            let angelHalo = values.angel * ANGEL_HALO / totalAngelScore;
            let lunaHalo = values.luna.tokens;
            let totalHalo = ancHalo + angelHalo + lunaHalo;

            csvData.push([
                delegator,
                values.angel.toString(),
                values.anc.toString(),
                values.luna.score.toString(),
                angelHalo.toFixed(6),
                ancHalo.toFixed(6),
                lunaHalo.toFixed(6),
                totalHalo.toFixed(6),
                
                (totalHalo * 0.1).toFixed(6),
                (totalHalo * 0.15).toFixed(6),
                (totalHalo * 0.2).toFixed(6),
                (totalHalo * 0.4).toFixed(6),
                values.luna.missing_tokens.toFixed(6),
            ]);
        }

        this.setState({ csvData, csvPrepared: true });
    }
}

export default HomePage;
